import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Layout from 'utils/hocs/layout';
import { applyContexts } from 'utils/providers';

// includes global styles used by library, but optional, like resets
import '@on2-dev/tyt-general-resources/styles/global.css';

// the library styles
import '@on2-dev/tyt-general-resources/styles/index.css';

// includes global styles used by library, but optional, like resets
import 'utils/styles/_globals.scss';

import 'utils/styles/_reset.scss';

function On2Frontend({ Component, pageProps }) {
  const router = useRouter();

  if (router.route === '/sitemap.txt') {
    return <Component {...pageProps} />;
  }

  return applyContexts(
    <Layout>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="sitemap" type="application/xml" title="Sitemap" href="/api/sitemap.xml" />
      </Head>
      {/* <StyleContext.Provider value={{ insertCss }}> */}
      <Component {...pageProps} />
      {/* </StyleContext.Provider> */}
    </Layout>,
    { pageProps }
  );
}

export default On2Frontend;
