import React from 'react';

import AnchorLink from 'components/__atoms/anchor-link';
import Wrapper from 'components/__atoms/wrapper';
import Section from 'components/__atoms/section';
import footerData from './footer-data';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism Footer
 *
 * The portal footer
 */

function Footer(props) {

  const {
    footerMenuData = [],
    footerSecondaryMenuData = footerData?.footerSecondaryMenuData || [],
    spacing = '5',
    bgColor = 'red'
  } = props;

  return (
    <Wrapper className={css['organisms__footer']}>
      <Wrapper
        className={css['footer']}
        data-testid="footer-wrapper"
        bgColor={bgColor}
        spacing={spacing}
      >
        <Section data-testid="footer-section" className={css['section-footer']}>
          <div className={css['footer-header']}>
             <img className={css['header-logo__image']} src="/images/logos/logo-tservice-horizontal.svg" alt="Toyota" />
          </div>
          <div className={css['footer-content']}>
            <div className={css['footer-content__content']}>
              {footerMenuData?.length > 0 && (
                <ul className={css['footer-links']}>
                  {footerMenuData.map((item, index) => {
                    return (
                      <li key={index}>
                        <AnchorLink
                          color="white"
                          linkLabel={item?.anchorText}
                          isExternal={item?.isExternal}
                          size="small"
                          link={item?.url}
                          data-testid={`footer-link-${index}`}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </Section>
      </Wrapper>
      {footerSecondaryMenuData?.length > 0 && (
        <Wrapper
          spacing="3"
          bgColor="gray-6"
          className={css['footer-links-list']}
          data-testid="footer-secondary-wrapper"
          >
          <Section tag="div">
            <ul className={css['links-list']}>
              {footerSecondaryMenuData.map((item, index) => {
                return (
                  <li key={index}>
                    <AnchorLink
                      color="white"
                      linkLabel={item?.anchorText}
                      size="small"
                      hasArrow={false}
                      isExternal={item?.isExternal}
                      link={item?.url}
                      data-testid={`footer-secondary-link-${index}`}
                    />
                  </li>
                );
              })}
            </ul>
          </Section>
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default Footer;
