const footerData = {
  footerSecondaryMenuData: [
    {
      anchorText: 'Termo de uso ',
      url:'https://media.toyota.com.br/cc60c58a-e15d-4564-9f82-519b3e0f3573.pdf',
      isExternal: false,
    },
    {
      anchorText: 'Politica de privacidade ',
      url:'https://media.toyota.com.br/18e5c50c-b52f-4988-a603-d146561744b6.pdf',
      isExternal: false,

    }
  ]
};

export default footerData;
